import { useMemo } from 'react';

import { useMatches } from '@remix-run/react';

import type { User } from '~/types';

import { parseJwt } from './parse-jwt';

export function useMatchesData(id: string): Record<string, unknown> | undefined {
  const matchingRoutes = useMatches();
  const route = useMemo(() => matchingRoutes.find((route) => route.id === id), [matchingRoutes, id]);
  return route?.data as Record<string, unknown> | undefined;
}

export function useOptionalUser(): User | undefined {
  const data = useMatchesData('root');
  if (!data || !data.token) {
    return undefined;
  }

  return parseJwt(data.token as string) as User;
}

const DEFAULT_REDIRECT = '/';

export function safeRedirect(
  to: FormDataEntryValue | string | null | undefined,
  defaultRedirect: string = DEFAULT_REDIRECT,
) {
  if (!to || typeof to !== 'string') {
    return defaultRedirect;
  }

  if (!to.startsWith('/') || to.startsWith('//')) {
    return defaultRedirect;
  }

  return to;
}
